import wAxios from '@/plugins/w/axios'
const APP_PREFIX = 'drilleo_'

const initState = {
	code: localStorage.getItem(`${APP_PREFIX}code`) || '',
	info: JSON.parse(localStorage.getItem(`${APP_PREFIX}info`) || 'null') || ''
}

export default {
	namespaced: true,
	state: initState,
	mutations: {
		info(state: any, data: { code: string; info: any}) {
			state.code = data.code
			state.info = data.info
			localStorage.setItem(`${APP_PREFIX}code`, data.code)
			localStorage.setItem(`${APP_PREFIX}info`, JSON.stringify(data.info))
		},
		infoReset(state: any) {
			state.code = ''
			state.info = null
			localStorage.removeItem(`${APP_PREFIX}code`)
			localStorage.removeItem(`${APP_PREFIX}info`)
		}
	},
	actions: {
		async getInfo({ commit }: any, code: string) {
			let response
			try {
				response = await wAxios.get_auth_data(`student/exams/${code}`)
			} catch (err) {
				console.error(err)
				throw err
			}

			commit('info', { code, info: response })
		}
	},
	getters: {
		testname(state: any) {
			return state.info?.exam?.name
		}
	}
}
