import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/plugins/app/test/user.store'
import test from '@/plugins/app/test/test.store'
import group from '@/plugins/app@group/group.store'
import wAuth from '@/plugins/w/authStore/wAuth.store'

Vue.use(Vuex)

export default new Vuex.Store<any>({
	modules: {
		user,
		test,
		wAuth,
		group
	}
})
