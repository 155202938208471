import Vue from 'vue'
import App from './App.vue'
import router from './plugins/app/_config/router'
import store from './plugins/app/_config/store'
import '@/plugins/app@utils/axios/axios'
import '@/plugins/app@utils/alert/alert'
import '@/ionic'
import '@/plugins/app/_theme/assets/styles/main.sass'

// analytics
import Analytics from '@/plugins/w/analytics/'
Analytics.addGoogleAnalytics(router)

// vue moment - filter for date formatting in template
import VueMoment from 'vue-moment'
Vue.use(VueMoment)

// wToast
import wToast from '@/plugins/w/toast'
Vue.use(wToast)

// translations
import i18n from '@/plugins/w/translate'
import Translations from '@/plugins/w/translate/translations'
Translations.setup('sk', { //default language
	sk: 'Slovak'
})

Vue.config.productionTip = false

new Vue({
	router,
	store,
	// @ts-expect-error
	i18n,
	render: (h) => h(App)
}).$mount('#app')
