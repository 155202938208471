import wAxios from '@/plugins/w/axios'
// import { APP_PREFIX } from '..'

const APP_PREFIX = 'drilleo_'

export default {
	namespaced: true,
	state: {
		name: localStorage.getItem(`${APP_PREFIX}name`) || '',
		hash: localStorage.getItem(`${APP_PREFIX}hash`) || '',
		code: ''
	},
	mutations: {
		joined(state: any, data: any) {
			state.name = data.name
			state.hash = data.hash
			state.code = data.code
			localStorage.setItem(`${APP_PREFIX}name`, data.name)
			localStorage.setItem(`${APP_PREFIX}hash`, data.hash)
		},
		reset(state: any) {
			state.name = ''
			state.hash = ''
			state.code = ''
			localStorage.removeItem(`${APP_PREFIX}name`)
			localStorage.removeItem(`${APP_PREFIX}hash`)
		}
	},
	actions: {
		async join({ commit }: any, data: { code: string; name: string}) {
			let response
			try {
				response = await wAxios.post_auth_data(`student/exams/${data.code}/join`, { name: data.name })
			} catch (err) {
				console.error(err)
				throw err
			}

			commit('joined', { name: data.name, hash: response.hash, code: data.code })
			commit('test/info', { code: data.code, info: response }, { root: true })
			return response
		}
	},
	getters: {
		isLoggedIn: (state) => !!state.hash
	}
}
