import wAxios from '@/plugins/w/axios'

export default {
	namespaced: true,
	state: {
		groups: [],
	},
	mutations: {
		groups(state, groups) {
			console.log(groups)
			state.groups = groups.data
		}
	},
	actions: {
		async fetchGroups({ commit }) {
			try {
				const { data } = await wAxios.get_auth('group-member/groups')
				commit('groups', data)
			} catch (err) {
				console.log(err)
			}
		},
		async joinGroup({ commit, dispatch }, hash) {
			try {
				await wAxios.post_auth(`group-member/groups/join/${hash}`)
				dispatch('fetchGroups')
			} catch (err) {
				console.log(err)
			}
		}
	},
	getters: {
		getGroupInvites: (state) => state.groups?.length ? state.groups.filter((group) => !group.is_active) : [],
		getJoinedGroups: (state) => state.groups?.length ? state.groups.filter((group) => group.is_active) : []
	}
}
