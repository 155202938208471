import axios, { AxiosRequestConfig } from 'axios'

import { encodeGetParams, strToRegex } from './moxios.util'

localStorage['isMoxios'] = false

class MoxiosConfig {
	name: string;
	url: string;
	reUrl: string;
	matchMethod: string;
	fname: string;

	constructor(name: string, url?: string, reUrl?: string, matchMethod?: string, fname?: string) {
		this.name = name

		if (!name) {
			this.url = url || ''
			this.reUrl = reUrl || ''
			this.matchMethod = matchMethod || ''
			this.fname = fname || ''
			return
		}

		this.url = name.split(' ')[1]
		this.reUrl = strToRegex(this.url)
		this.matchMethod = name.split(' ')[0].toLowerCase()
		this.fname = ''
	}
}

class Moxios {
	//states
	_mocks: MoxiosConfig[] = []

	constructor() {
		axios.interceptors.request.use(this._redirectRequestToMock)
	}

	mock(append: string[]) {
		for (const a of append) {
			// custom config?
			this._mocks.push(new MoxiosConfig(a))
		}
	}

	_redirectRequestToMock = (config: AxiosRequestConfig) => {
		if (localStorage['isMoxios'] == 'false') {
			return config
		}

		const url = config.url?.replace(/https?:\/\/[^/?]+/, '')

		let mockedConfig: MoxiosConfig = new MoxiosConfig('')
		for (const mock of this._mocks) {
			const matchMethod = config.method == mock.matchMethod
			const match = url?.match(mock.reUrl)

			if (matchMethod && match) {
				if (!mockedConfig.name) {
					mockedConfig = mock
				} else {
					mockedConfig = mock.reUrl.length > mockedConfig.reUrl.length ? mock : mockedConfig
				}
			}
		}

		if (mockedConfig.name) {
			if (!mockedConfig.fname)
				mockedConfig.fname = `/mock${mockedConfig.url}.${config.method?.toUpperCase()}.json`
			const c: Partial<AxiosRequestConfig> = {
				method: 'get',
				url: mockedConfig.fname + '?___MOCK___&' + encodeGetParams(config.data || {}),
			}
			config = { ...config, ...c }
			// console.log('MOXIOS: ', mocked.name, mocked.url, c.url)
		}

		return config
	}

}

export default new Moxios()
