import { AlertController } from '@ionic/vue/dist/types/controllers/alert-controller'

export function mapError(error: any) {
	if (typeof error === 'string') {
		return error
	} else {
		if (!error.response?.data?.error) {
			return 'Unknown error'
		}

		if (typeof error.response.data.error === 'string') {
			return error.response.data.error
		} else {
			return error.response.data.error.name[0]
		}
	}
}

export function translateError(error: string) {
	if (error == 'The name has already been taken.') {
		return 'Toto meno je už použité! Zvoľte si prosím iné.'
	}

	return error
}

export async function alertError(alertController: AlertController, message: string) {
	const alert = await alertController.create({
		message: translateError(mapError(message)),
		buttons: [ {
			text: 'OK',
			role: 'cancel'
		} ]
	})

	await alert.present()
}
