import store from '@/plugins/app/_config/store'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import wAuthGuard from '@/plugins/w/authStore/loginGuard'

Vue.use(VueRouter)

function _testGuard(to: any, from: any, next: any) {
	if (!store.getters['user/isLoggedIn']) {
		console.error('Nie ste prihlásený do testu!')
		next({ name: from.name || 'Login' })
	} else {
		next()
	}
}

function _loggedInGuard(to, from, next) {
	if (store.getters['wAuth/isLoggedIn']) {
		next({ name: 'Profile' })
	} else {
		next()
	}
}

const wRoute = (name: string, path: string, component, beforeEnter?) => ({ name, path, component, beforeEnter })


const routes: Array<RouteConfig> = [
	{
		path: '/',
		redirect: '/prihlasenie'
	},
	wRoute('TestInfo',          '/testinfo',                          			() => import('@/plugins/app/test-info/test-info.vue')),
	wRoute('Test',              '/test',                              			() => import('@/plugins/app/test/test.vue'),                 _testGuard),
	wRoute('Results',           '/vysledky',                           			() => import('@/plugins/app/results/results.vue'),           _testGuard),
	wRoute('Register',          '/registracia',                          		() => import('@/plugins/app/register/register.vue'),         _loggedInGuard),
	wRoute('Verify',            '/aktivovat-ucet/:key?/:email?',         		() => import('@/plugins/w@screen/verify/verify.vue'),        _loggedInGuard),
	wRoute('Login',             '/prihlasenie',                           	() => import('@/plugins/app/login/login.vue'),               _loggedInGuard),
	wRoute('Profile',           '/profil/:tab?',                     				() => import('@/plugins/app/profile/profile.vue'),           wAuthGuard),
	wRoute('ExamHistoryDetail', '/historia-testu/:examhash/:id/:question?',	() => import('@/plugins/app/exam-history/exam-history.vue'), wAuthGuard),
	wRoute('Groups',            '/skupiny',                            			() => import('@/plugins/app@group/groups.vue'),              wAuthGuard),
	wRoute('JoinGroup',         '/skupiny/pridat/:hash',              			() => import('@/plugins/app@group/join-group/join-group.vue'),     wAuthGuard),
	wRoute('Join',              '/spustit-test/:code/:name?',             	() => import('@/plugins/app/join/join.vue')),
	wRoute('ResetPassword',     '/resetovat-heslo/:code/:email',      			() => import('@/plugins/w@screen/reset-password/reset-password.vue')),
]

const router = new VueRouter({
	mode: 'history',
	routes,
})

export default router
