
import moxios from '@/plugins/w/moxios/moxios'

//Moxios

// POZOR - specifickejsie url musia ist navrch (match hlada prvu zhodu v stringu a nie presnu)
moxios.mock([
	'POST /api/v1/student/exams/(code)/student/questions/(id)',
	'GET /api/v1/student/exams/(code)/student/questions',
	'GET /api/v1/student/exams/(code)/student',
	'POST /api/v1/student/exams/(code)/join',
	'GET /api/v1/student/exams/(code)',
])
