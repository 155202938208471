import axios from 'axios'
import store from '@/plugins/app/_config/store'

let isRefreshing = false
let refreshHook = Promise.resolve(null)

async function _unauthorised(error) {
	if (!error.response || error.response.status != 401) {
		return Promise.reject(error)
	}

	if (!isRefreshing) {
		refreshHook = new Promise((resolve, reject) => {
			store.dispatch('wAuth/refreshToken')
				.then((token) => resolve(token))
				.catch((error) => reject(error))
				.finally(() => isRefreshing = false)
		})

		isRefreshing = true
	}

	try {
		const token = await refreshHook
		if (token) {
			error.config.headers = {
				'Authorization': `bearer ${token}`
			}

			return await axios.request(error.config)
		}
		return Promise.reject('Token not refreshed!')
	} catch (error) {
		console.log(error)
	}
}

axios.interceptors.response.use(
	(response) => response,
	(error) => _unauthorised(error)
)
