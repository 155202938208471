import Vue from 'vue'
import VueGtag from 'vue-gtag'
import VueRouter from 'vue-router'

export default {
	addGoogleAnalytics(router: VueRouter, options: object = {}) {
		Vue.use(VueGtag, {
			config: {
				id: process.env.VUE_APP_GTAG_ID,
			},
			...options
		}, router)
	}
}

