import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { ref } from '@vue/composition-api'
import utils from './utils'

Vue.use(VueCompositionAPI)

export default {
	_isLoading: ref(false),
	_ionic: null,

	install() {
		Vue.prototype.$wToast = this
		this._ionic = Vue.prototype.$ionic
	},

	isLoading() {
		return this._isLoading.value
	},

	stopLoading() {
		this._isLoading.value = false
	},

	async error(error = 'Error', position: any = 'top') {
		this._isLoading.value = false

		const toast = await this._ionic.toastController.create({
			message: utils.parseToErrors(error),
			color: 'danger',
			position: position,
			duration: 2000
		})

		toast.present()
	},

	async success(message = 'Success', position: any = 'top') {
		this._isLoading.value = false

		const toast = await this._ionic.toastController.create({
			message: utils.parseToErrors(message),
			color: 'success',
			position: position,
			duration: 2000
		})

		toast.present()
	}
}

